import React  from 'react';
import '../../Styling/App.css';
import styles from '../../Styling/Dialogue.module.css';
import Exercise from './Exercise';
import Paragraph from './Paragraph'
import Question from './Question';
import Message from './Message';

function DialogueDylan() {

  const narrative = {
    para1: "Facts first.\nTogether recap his story.",
    q1: "Where's the violence?",
    para2: "Let's explore your reaction to it.",
    q2: "What did it make you feel or think?",
    para3: "Dylan's brother, Leigh, had a role to play in this scene.\nHe asks Dylan...",
    message1: "Do you find it funny?",
    q3: "Was this an effective question?",
    para4: "Leigh encourages Dylan to talk openly about the video he's been sent. Dylan replies...",
    message2: "I dunno. Maybe. Sometimes.",
    q4: "What did you think of Dylan's response?",
    para5: "It's a tricky conversation to navigate but Leigh tells Dylan...", 
    message3: "I'm not having a go",
    para6: "Think about Leigh and Dylan's conversation and maybe some tough conversations you've had.",
    q5: "What might make a conversation about a tough topic easier?",
    exercise: "Dylan decides to send a text to the group that expresses how he feels.\nDraft some alternative messages that Dylan could have sent on the group chat. Think about what might have happened after Dylan sent it.\nIf you're in a group, share them back to one another."
  }

  return (
    <div className={styles.dialogueSection}>
      <div className={styles.dialogueTimeline}></div>
      <div className={styles.dialogueContent}>
        <Paragraph text={narrative.para1}/>
        <Question text={narrative.q1}/>
        <Paragraph text={narrative.para2}/>
        <Question text={narrative.q2}/>
        <Paragraph text={narrative.para3}/>
        <Message text={narrative.message1}/>
        <Question text={narrative.q3}/>
        <Paragraph text={narrative.para4}/>
        <Message text={narrative.message2}/>
        <Question text={narrative.q4}/>
        <Paragraph text={narrative.para5}/>
        <Message text={narrative.message3}/>
        <Paragraph text={narrative.para6}/>
        <Question text={narrative.q5}/>
        <Exercise text={narrative.exercise}/>
      </div>
    </div>
  );
}

export default DialogueDylan;