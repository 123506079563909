import React from 'react';
import '../../Styling/App.css';
import styles from '../../Styling/Dialogue.module.css';

function Paragraph({text}) {

  // Handle text with carriage returns. Split into multiple paragraphs. 
  // ? makes the variable an optional
  // key needed - index of the resultantarray item used
  let splitText = text?.split('\n').map((i, index) => 
    <p key={index}>
      {i}
    </p>
  );

  return (
    <div className={styles.paragraphContainer}>
      <div className={styles.paragraphText}>{splitText}</div>
    </div>
  );
}

export default Paragraph;