import React from 'react';
import '../Styling/App.css';
import styles from '../Styling/Pause.module.css';
import { InView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function Pause() {
  return (
    <InView threshold={0.25}>
      {({ ref, inView }) => (
        <motion.div className={styles.pauseContainer} ref={ref}
          initial={{width: '80%'}}
          animate={inView ? {width: '100%'} : {width: '80%'}}
          transition={{delay: 0.1, duration: 0.3}}
        ></motion.div>
      )}
    </InView>
  );
}

export default Pause;