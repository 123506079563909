import React from 'react';
import '../Styling/App.css';
import styles from '../Styling/Arrow.module.css';

function Arrow() {
  return (
    <div className={styles.arrowBackground}>
      <div className={styles.squareLeft}></div>
      <div className={styles.squareRight}></div>
      <div className={styles.triangleLeft}></div>
      <div className={styles.triangleRight}></div>
    </div>
  );
}

export default Arrow;