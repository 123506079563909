import React, { useState, useEffect, useRef }  from 'react';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import '../Styling/App.css';
import styles from '../Styling/Film.module.css';
import ReactPlayer from 'react-player';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { InView } from 'react-intersection-observer';

import loading from '../images/loading.gif';

function Film({filmUrl, name}) {

  // Reference Film Container
  const ref = useRef(null);

  const [loaderVisibile, setLoaderVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  // Adds a short delay before removing loader after video is ready (onReady in React Player)
  const removeLoader = () => {
    setTimeout(() => {
      setLoaderVisible(true);
    }, 1000)
  }

  useEffect(() => {
    if(isPlaying){
      // Scroll reference element (Film Container) and scroll smoothly into view (centre)
      ref.current?.scrollIntoView({block: "center", behavior: 'smooth'});
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [isPlaying])

  return (
    <>
    <AnimatePresence>
    {isPlaying &&
      <motion.div className={styles.filmBlackout}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
      />
    }
    </AnimatePresence>

    <div className={styles.filmSection}>

      <div className={cx(styles.filmContainer, name==="dylan" ? styles.dylan : "")}>

        <div className={styles.filmTimeline}></div>

        {/* Adjust styling for Dylan's film as aspect ratio is different (see Film.js) */}
        <div className={cx(styles.playerWrapper, name==="dylan" ? styles.dylan : "")} ref={ref}>
          <ReactPlayer
          className={styles.reactPlayer}
          url={filmUrl}
          width='100%'
          height='100%'
          controls={true}
          loop={false}
          playing={isPlaying}
          onReady= {() => { removeLoader() }}
          onPlay = {() =>  setIsPlaying(true) }
          onPause = {() => setIsPlaying(false) }
          />
          <AnimatePresence>
            {!loaderVisibile &&
              <motion.div className={styles.filmLoadingContainer}
                initial={{opacity: 1}}
                exit={{opacity: 0}}
              >
                  <img src={loading} alt="Loading Gif"/>
              </motion.div>
            }
          </AnimatePresence>
        </div>

        <AnimatePresence>
          {!isPlaying &&
          <InView threshold={0.25}>
            {({ ref, inView }) => (
              <motion.div className={styles.filmIntro} ref={ref}
                initial={{opacity: 0, marginLeft: "-3rem"}}
                animate={inView ? { opacity: 1, marginLeft: 0} : {opacity: 0, marginLeft: "-3rem"}}
                transition={{delay: 0.2}}
                exit={{opacity: 0, transition:{duration: 0}}}
              >
                <p className={cx(styles.filmIntroText, "filmIntroText")}>This was {name}'s Experience</p>
              </motion.div>
            )}
          </InView>
        }
        </AnimatePresence>

        <AnimatePresence>
          {!isPlaying &&
            <motion.h2 className={styles.filmName}
              initial={{opacity: 0, marginTop: "-3rem"}}
              animate={{opacity: 1, marginTop: 0}}
              transition={{delay: 0.1}}
              exit={{opacity: 0, transition:{duration: 0}}}
            >
              {name}
            </motion.h2>
          }
        </AnimatePresence>
      </div> 
    </div>

    {/* Mobile film intro version positioned under entire film section */}
    <InView threshold={0.25}>
      {({ ref, inView }) => (
        <motion.div className={styles.filmIntroMobile} ref={ref}
          initial={{opacity: 0}}
          animate={inView ? {opacity: 1} : {opacity: 0}}
          transition={{delay: 0.3}}
        >
          <p className={cx(styles.filmIntroText, "filmIntroText")}>This was {name}'s Experience</p>
        </motion.div>
      )}
    </InView>
    </>
  );
}

export default Film;

// Vimeo - go to video > Embed, and take the URL with the 'h' value included
// React Player Docs - https://www.npmjs.com/package/react-player
// React Player Vid - https://www.youtube.com/watch?v=Rq5SEhs9lws&ab_channel=Skillthrive
