import React  from 'react';
import '../../Styling/App.css';
import styles from '../../Styling/Dialogue.module.css';

function Message({text}) {

  let splitText = text?.split('\n').map((i, index) => 
    <p className="messageFont" key={index}>
      {i}
    </p>
  );

  return (
    <div className={styles.messageContainer}>
      <div className={styles.messageText}>{splitText}</div>
    </div>
  );
}

export default Message;