import React from 'react';
import cx from 'classnames';
import '../Styling/App.css';
import styles from '../Styling/Resources.module.css';
import Typewriter from 'typewriter-effect';
import { InView } from 'react-intersection-observer';

function Resources() {
  return (
    <div className={cx(styles.resourcesSection)}>

      <div className={cx("mainContainer", styles.resourcesContent)}>

        {/* Observe h2 and hide and show <Typewriter> based on value of inView  */}
        <InView threshold={0.25} triggerOnce>
          {({ ref, inView }) => ( 
            <h2 className={styles.resourcesTitle} ref={ref}>
              {inView &&
                <Typewriter
                  options={{
                    delay: 150,
                    cursor: ''
                  }}
                  onInit={(typewriter) => {
                    typewriter
                    .typeString("L")
                    .pauseFor(500)
                    .typeString("in")
                    .pauseFor(200)
                    .typeString("ks")
                    .start()
                  }}
                />
              }
            </h2>
          )}
        </InView>
        <div className={styles.resourcesLinks}>
          <p><a href="https://www.police.uk/pu/notices/streetsafe/street-safe/" target="_blank" rel="noreferrer">StreetSafe</a> is a service for anyone to anonymously tell the police about public places where you have felt or feel unsafe.</p>
          
          <p><a href="https://loverespect.co.uk/" target="_blank" rel="noreferrer">Women's Aid</a> is the national charity working to end domestic abuse against women and children.</p>
          
          <p><a href="https://www.endviolenceagainstwomen.org.uk/" target="_blank" rel="noreferrer">End Violence Against Women</a> are a group of feminist organisations and experts from across the UK, working to end violence against women and girls in all its forms.</p>

          <p><a href="https://swadomesticabuse.org/" target="_blank" rel="noreferrer">SWA</a> Swindon Domestic Abuse Support is committed to empowering and supporting victims and survivors of domestic abuse by providing a range of flexible and accessible services that promote positive change.</p>

          <p><a href="https://www.ourstreetsnow.org/" target="_blank" rel="noreferrer">Our Streets Now</a> is a movement to end Public Sexual Harassment in the UK through cultural and legislative change.</p>

          <p><a href="https://stepswindon.co.uk/" target="_blank" rel="noreferrer">Step Swindon</a> work creatively using therapeutic group work to enable children and young people to make a positive contribution to society.</p>

          {/* <p><a href="https://www.iprovefit.co.uk/" target="_blank" rel="noreferrer">Iprovefit</a> is a mentoring company that focuses on breaking barriers to learning - engaging with learners who may struggle in the mainstream environment and those outside of mainstream in alternative provisions.</p>

          <p><a href="https://www.barnardosrealloverocks.org.uk/" target="_blank" rel="noreferrer">Real Love Rocks</a> is a ground-breaking education and awareness raising programme developed by Barnardo's Safer Futures Trauma Services. It promotes children and young people's rights to healthy, consensual and safe relationships with their peers, partners, and family.</p> */}
        </div>
      
      </div>
    </div>
  );
}

export default Resources;