import React, { useState } from 'react';
import cx from 'classnames';
import '../Styling/App.css';
import styles from '../Styling/Credits.module.css';
import Typewriter from 'typewriter-effect';
import {motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import chevronDown from '../images/down-chevron-icon.png';
import logo1 from '../images/logos-1.jpg';
import logo2 from '../images/logos-2.jpg';

function Credits() {

  const [toggleCredits, setToggleCredits] = useState(false);

  const toggleCheck = () => {
    setToggleCredits(current => !current);
  }

  const toggleCheckKeyboard = (e) => {
    // Return Key (13) 
    if(e.keyCode === 13){
      setToggleCredits(current => !current);
    }
  }


  return (
    <>
    <div className={cx(styles.creditsSection)}>

      <div className={cx("mainContainer", styles.creditsContent)}>

      {/* Observe h2 and hide and show <Typewriter> based on value of inView  */}
      <InView threshold={0.25} triggerOnce>
          {({ ref, inView }) => ( 
            <h2 className={styles.creditsTitle} ref={ref}>
              {inView &&
                <Typewriter
                  options={{
                    delay: 150,
                    cursor: ''
                  }}
                  onInit={(typewriter) => {
                    typewriter
                    .typeString("Cr")
                    .pauseFor(500)
                    .typeString("edi")
                    .pauseFor(300)
                    .typeString("ts")
                    .start();
                  }}
                />
              }
            </h2>
          )}
        </InView>

        <div className={styles.creditsActors}>
          <div className={styles.actorsItem}>
            <p className={cx('paraAccent', styles.actorsFilmTitle)}>Leah</p>
            <div className={styles.actors}>
              <p>Leah - Freya Caroll</p>
              <p>Sam - Anna Griffiths</p>
            </div>
          </div>
          <div className={styles.actorsItem}>
            <p className={cx('paraAccent', styles.actorsFilmTitle)}>Dylan</p>
            <div className={styles.actors}>
              <p>Dylan - Zac Pile</p>
              <p>Leigh - Patrick Lock</p>
            </div>
          </div>
          <div className={styles.actorsItem}>
            <p className={cx('paraAccent', styles.actorsFilmTitle)}>Grace</p>
            <div className={styles.actors}>
              <p>Grace - Chloe Stannage</p>
              <p>Miles - Terelle Miller</p>
            </div>
          </div>
        </div>
        <div className={styles.creativeTeam}>
          <div className={styles.creativeTeamHeader}>
            <p className={cx('paraAccent', styles.creativeTeamTitle)}>Creative Team</p>
            {/* TabIndex = 0 to allow element to be focused */}
            <img src={chevronDown} className={cx(styles.downArrowIcon, toggleCredits ? styles.rotateIcon : "")} alt="" tabIndex="0" onClick={toggleCheck} onKeyDown={toggleCheckKeyboard}/>
          </div>
            {toggleCredits &&
              <motion.div className={styles.creativeTeamContent}
                initial={{height: 0}}
                animate={{height: "auto"}}
                transition={{duration: 0}}
              >
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{duration: 0.3, delay: 0.1}}
                >
                  <div className={styles.creativeTeamSection}>
                    <p>Created by Sarah Butcher and Helen Morley for Prime Theatre</p>
                    <p>Written by Sarah Butcher</p>
                    <p>Directed by Gurch Singh and Sarah Butcher</p>
                  </div>
                  <div className={styles.creativeTeamSection}>
                    <p className='boldParagraph'>For Prime Theatre</p>
                    <p>Producer - Helen Morley</p>
                    <p>Sarah Wrixon - Company Manager</p>
                    <p>Artistic Director - Mark Powell</p> 
                  </div>
                  <div className={styles.creativeTeamSection}>
                    <p className='boldParagraph'>For Create Studios</p>
                    <p>DOP - Thom Hobbs</p>
                    <p>1st AC - Tom Arthur</p> 
                    <p>Production Manager - Marilyn Fitzgerald</p>
                    <p>Production Coordinator - Kiara Wakeley</p>
                    <p>Runner - Finley Norris</p>
                    <p>Editor - Caitlin Amer</p>
                  </div>
                  <div className={styles.creativeTeamSection}>
                    <p className='boldParagraph'>Web Experience</p>
                    <p>Designed and built by Lee Scott</p>
                    <p>Workshop content written by Sarah Butcher and Helen Morley</p>
                    <p>Swindon Borough Council Consultant - Lin Williams</p>
                  </div>
                  <div className={styles.creativeTeamSection}>
                    <p className='boldParagraph'>Funded by</p>
                    <p>Safer Streets and Swindon Borough Council</p>
                  </div>
                  <div className={styles.creativeTeamSection}>
                    <p className='boldParagraph'>Prime Workshop Participants</p>
                    <p>Narcisa Serban, Bobby Seymour, Ava Roberts, Steph Healey, Ellis Evason, Eris Carswell, Indiana Parsons, Isla Blackwell, George Sargent, Lauren Tanner</p>
                  </div>
                  <div className={styles.creativeTeamSection}>
                    <p className='boldParagraph'>School Workshop Participants</p>
                    <p>Pupils from Lawn Manor Academy:<br/>
                       Tabitha Awin, Chanice Baughn, Phoebe Bean, James Callis, Charlie Clack, Morgan Clift, Martha Dixon, Ryhanna Doyley, Bradley Duncan, Kenisha Fernandes, Mohammad Hussain, Catie Johnson, Eros Llanes, Robyn Luker, Kellen Maisey, Uzziel Mendonca, Shenaya Merrick, Sheeba Miranda, Connor Porter, Ella-Louise Price, Sudiksha Rai, Raisa Rodrigues, Asheesh Sharma, Edsel Soares, Eva Subba, Marcel Tarlowski, Ruby Thaire-Preston, Abdul Uddin, Henry Walters, Robert Western</p>
                  </div>
                  <div className={styles.creativeTeamSection}>
                    <p className='boldParagraph'>Thanks to...</p>
                    <p>Pattern Church, Ieva Delininkaityte, Maya Meeus-Jones and family</p>
                  </div>
                  
                </motion.div>
              </motion.div>
            }
        </div>
      </div>
    </div>
    <div className={cx("mainContainer", styles.logos)}>
      <img src={logo1} alt="logos"/>
      <img src={logo2} alt="logos"/>
    </div>
    </>
  );
}

export default Credits;