import React  from 'react';
import '../../Styling/App.css';
import styles from '../../Styling/Dialogue.module.css';
import cx from 'classnames';
import Exercise from './Exercise';
import Paragraph from './Paragraph'
import Question from './Question';
import Message from './Message';

function DialogueGrace() {

  const narrative = {
    para1: "Facts first.\nTogether, recap her story.",
    q1: "Where's the violence?",
    para2: "Let's explore your reaction to it.",
    q2: "What did it make you feel or think?",
    para3: "Miles, Grace's friend, notices a change in her behaviour and asks...",
    message1: "Are you okay?",
    para4: "A question that gives Grace the opportunity to talk, like Sam's question to Leah.",
    q3: "What might have stopped Grace wanting to talk about what's happening to her?",
    para5: "When Miles says to Grace that she needs to tell her parents Grace is reluctant to, she says...",
    message2: "No way - they'll say it's my fault.",
    para6: "Blame culture in violence against women and girls is a complex issue.",
    q4: "Why might Grace feel like people might think it's her fault?",
    para7: "We don't see the name of the texter and we don't know their relationship...",
    q5: "Who might be texting Grace?",
    exercise: "Miles reassures Grace that they will work out what to do together.\nTake a quiet moment on your own to identify 3 people you feel safest with, people that you could go to if you were in a situation like Grace."
  }

  return (
    <div className={styles.dialogueSection}>
      <div className={styles.dialogueTimeline}></div>
      <div className={cx(styles.dialogueContent, styles.dialogueContentGrace)}>
        <Paragraph text={narrative.para1}/>
        <Question text={narrative.q1}/>
        <Paragraph text={narrative.para2}/>
        <Question text={narrative.q2}/>
        <Paragraph text={narrative.para3}/>
        <Message text={narrative.message1}/>
        <Paragraph text={narrative.para4}/>
        <Question text={narrative.q3}/>
        <Paragraph text={narrative.para5}/>
        <Message text={narrative.message2}/>
        <Paragraph text={narrative.para6}/>
        <Question text={narrative.q4}/>
        <Paragraph text={narrative.para7}/>
        <Question text={narrative.q5}/>
        <Exercise text={narrative.exercise}/>
      </div>
    </div>
  );
}

export default DialogueGrace;