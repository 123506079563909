import React from 'react';
import cx from 'classnames';
import '../Styling/App.css';
import styles from '../Styling/Feedback.module.css';
import Typewriter from 'typewriter-effect';
import { InView } from 'react-intersection-observer';

function Feedback() {
  return (
    <div className={cx(styles.feedbackSection)}>

      <div className={cx("mainContainer", styles.feedbackContent)}>

        {/* Observe h2 and hide and show <Typewriter> based on value of inView  */}
      <InView threshold={0.25} triggerOnce>
          {({ ref, inView }) => ( 
            <h2 className={styles.feedbackTitle} ref={ref}>
              {inView &&
                <Typewriter
                  options={{
                    delay: 150,
                    cursor: ''
                  }}
                  onInit={(typewriter) => {
                    typewriter
                    .typeString("Oh")
                    .pauseFor(500)
                    .typeString("...")
                    .start()
                  }}
                />
              }
            </h2>
          )}
        </InView>
        <h3>Before you go</h3>

        <div className={styles.feedbackText}>
          <p>THIS IS WHEN . . . we ask you for your feedback!</p>
          <p>Please complete our 3 minute survey to tell us about your experience of the workshop.</p>
          {/* TabIndex = 1 on <a> element to prevent it being focused (as button focuses) */}
          <a href="https://forms.office.com/r/SN21X3ebhs" target="_blank" rel="noreferrer" tabIndex="1"><button><p>Take Survey</p></button></a>
        </div>

      </div>
    </div>
  );
}

export default Feedback;