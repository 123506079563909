import React, { useState } from 'react';
import './Styling/App.css';
import Lightbulb from './Components/Lightbulb';
import Entry from './Components/Entry';
import Hello from './Components/Hello';
import Intro from './Components/Intro';
import Story from './Components/Story';
import Pause from './Components/Pause';
import Outro from './Components/Outro';
import Credits from './Components/Credits';
import Resources from './Components/Resources';
import Feedback from './Components/Feedback';

function App() {

const [isDark, setisDark] = useState(true);
const [moveLightbulb, setMoveLightbulb] = useState(false);

// Styles to hide and show sections when scene is dark/light
// 50vh is just a number small enough not to create a scroll bar for mobile
// Use visibility so CSS can load but the elements can't be focused
const darkStyle = {
  visibility: "hidden",
  height: "50vh",
  overflow: "hidden"
};

const lightStyle = {
  visibility: "visible",
  height: "auto",
  overflow: "scroll"
};

  return (
    <div className="App">
        <Lightbulb isDark={isDark} setisDark={setisDark} moveLightbulb={moveLightbulb}/>
        <div className="main-wrapper" style={isDark ? darkStyle : lightStyle}>
          <Hello isDark={isDark}/>
          <Intro/>
          <Story name="leah"/>
          <Pause/>
          <Story name="dylan"/>
          <Pause/>
          <Story name="grace"/>
          <Outro/>
          <Credits/>
          <Resources/>
          <Feedback/>
        </div>
        <Entry moveLightbulb={moveLightbulb} setMoveLightbulb={setMoveLightbulb}/>
    </div>
  );
}

export default App;
