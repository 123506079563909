import React from 'react';
import cx from 'classnames';
import '../Styling/App.css';
import styles from '../Styling/Intro.module.css';
import { InView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function Intro() {

  // Variants for introNames animation to stagger children (see below link)
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 1
      }
    }
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  return (
    <>
      {/* Using react-interaction-observer with framer motion (see below link) */}
      {/* Add the reference to sentence about violence so introNames doesn't fade in too early (for standard laptop viewport heights)  */}
      <InView threshold={0.25}>
        {({ ref, inView }) => (
          <div className={styles.introSectTop}>
            <div className="mainContainer">
              <motion.div className={styles.introNames}
                variants={container}
                initial="hidden"
                animate={inView ? "show" : "hidden"}
                transition={{delay: 0.2}}
              >
                <motion.h5 variants={item}>Leah</motion.h5>
                <motion.h5 variants={item}>Dylan</motion.h5>
                <motion.h5 variants={item}>Grace</motion.h5>
              </motion.div>

              <div className={styles.violenceContainer} ref={ref}>
                <p className={cx(styles.violence, "biggerParagraph", "boldParagraph")}>Have each encountered violence against women and girls in some way</p>
              </div>
            </div>
          </div>
        )}
      </InView>

      <div className={styles.introSectBottom}>
        <div className={styles.introSectCenter}>
          <div className={styles.introSectCenterText}>
            <p>You might relate to them and their experiences.<br/> 
               You might not.
            </p>
          </div>
        </div>
        
        {/* Two versions - mobile breaks 'WATCH' accented text onto a new line */}
        <div className="mainContainer">
          <div className={styles.introWatchTalkListen}>
            <p>Either way, this is some time out to <span className="paraAccent">WATCH</span><br/>
                . . . and then to <span className="paraAccent">TALK</span><br/>
                . . . but also to <span className="paraAccent">LISTEN</span>
            </p>
          </div>
          <div className={styles.introWatchTalkListenMobile}>
            <p>Either way, this is some time out<br/>. . . to <span className="paraAccent">WATCH</span><br/>
                . . . and then to <span className="paraAccent">TALK</span><br/>
                . . . but also to <span className="paraAccent">LISTEN</span>
            </p>
          </div>  
        </div>
      </div>
    </>
  );
}

export default Intro;

// React-Interaction-Obserer with framer motion - https://brad-carter.medium.com/using-intersection-observer-and-framer-motion-for-scroll-based-animations-in-react-99a3d6d9ece
// Stagger Childen framer motion - https://www.framer.com/docs/transition/