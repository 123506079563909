import React from 'react';
import '../Styling/App.css';
import styles from '../Styling/Dots.module.css';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';

function Dots() {

  // Variants for dots animation to stagger children (see below link)
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 0.3 }
  }

  return (
    <div className={styles.dotContainer} tabIndex="-1">
      <InView threshold={0.25}>
        {({ ref, inView }) => (
          <motion.div className={styles.dots} ref={ref}
            variants={container}
            initial="hidden"
            animate={inView ? "show" : "hidden"}
            transition={{delay: 0.3}}
          >
            <motion.div className={styles.dot} variants={item}></motion.div>
            <motion.div className={styles.dot} variants={item}></motion.div>
            <motion.div className={styles.dot} variants={item}></motion.div>
            <motion.div className={styles.dot} variants={item}></motion.div>
            <motion.div className={styles.dot} variants={item}></motion.div>
          </motion.div>
        )}
      </InView>
    </div>
  );
}

export default Dots;