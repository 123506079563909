import React from 'react';
import cx from 'classnames';
import '../Styling/App.css';
import styles from '../Styling/Hello.module.css';
import downArrow from '../images/down-arrow-1px.png';
import Dots from './Dots';
import Typewriter from 'typewriter-effect';

function Hello({isDark}) {

  return (
    <div className={styles.helloBody}>
      
      <div className={styles.helloHeader}></div>
      
      <div className={cx(styles.helloContent, "mainContainer")}>
        
        {!isDark &&
        <h1 className={cx(styles.greeting, styles.greetingHello)}>
          <Typewriter
            options={{
              delay: 100,
              cursor: ''
            }}
            onInit={(typewriter) => {
              typewriter
              .typeString("H")
              .pauseFor(100)
              .typeString("el")
              .pauseFor(200)
              .typeString("l")
              .pauseFor(300)
              .typeString("o")
              // Takes the longest to write, so use this callback to handle all Typewriters
              // .callFunction(() => {
              //   setTypingComplete(true)
              // })
              .start()
            }}
          />
        </h1>
        }
        {!isDark &&
        <h1 className={cx(styles.greeting, styles.greetingHey)}>
          <Typewriter
            options={{
              delay: 150,
              cursor: ''
            }}
            onInit={(typewriter) => {
              typewriter
              .typeString("H")
              .pauseFor(500)
              .typeString("ey")
              .start()
            }}
          />
        </h1>
        }
        {!isDark &&
        <h1 className={cx(styles.greeting, styles.greetingHi)}>
          <Typewriter
            options={{
              delay: 50,
              cursor: ''
            }}
            onInit={(typewriter) => {
              typewriter
              .typeString("_")
              .pauseFor(150)
              .deleteAll()
              .pauseFor(150)
              .typeString("H")
              .pauseFor(400)
              .typeString("i")
              .start()
            }}
          />
        </h1>
        }
        
        <div className={styles.helloIntro}>
          {/* <Tagline/> */}
          <h4>This Is When...</h4>
          <p>. . . we spend some time hearing about three young people's experiences of <span className='highlightParagraph'>violence against women and girls.</span></p>
          <p>You've probably heard that phrase before "violence against women and girls". But what do we mean when we say 'violence' and how does it affect us all?</p>
        </div>
        
        <img src={downArrow} className={styles.downArrow} alt="Down Arrow"/>
       
        <div className={styles.helloDots}>
          <Dots/>
        </div>
        
      </div>
    </div>
  );
}

export default Hello;

// Typewriter Effect https://www.npmjs.com/package/typewriter-effect