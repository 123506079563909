import React from 'react';
import cx from 'classnames';
import '../Styling/App.css';
import styles from '../Styling/Story.module.css';
import Film from './Film';
import DialogueLeah from './Dialogue/DialogueLeah';
import DialogueDylan from './Dialogue/DialogueDylan';
import DialogueGrace from './Dialogue/DialogueGrace';
import Arrow from './Arrow';
import Dots from './Dots';

function Story({ name }) {
  return (
    <div className={cx(styles.storyContainer, "mainContainer")}>
      
      <div className={styles.downArrow}>
        <Arrow/>
      </div>
      
      <div className={styles.filmDots}>
        <Dots/>
      </div>

      {name === 'leah' &&
      <>
      <Film name={name} filmUrl="https://vimeo.com/764235372/1994965f65"/>
      <DialogueLeah/>
      </>
      }
      {name === 'dylan' &&
      <>
      <Film name={name} filmUrl="https://vimeo.com/764230476/2b5304639e"/>
      <DialogueDylan/>
      </>
      }
      {name === 'grace' &&
      <>
      <Film name={name} filmUrl="https://vimeo.com/764230813/830ad314ec"/>
      <DialogueGrace/>
      <div className={styles.lastDownArrow}>
        <Arrow/>
      </div>
      </>
      }
    </div> 
  );
}

export default Story;