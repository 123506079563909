import React, { useState }  from 'react';
import cx from 'classnames';
import '../../Styling/App.css';
import styles from '../../Styling/Dialogue.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faCheck } from '@fortawesome/free-solid-svg-icons';

function Exercise({text}) {

  const [checked, setChecked] = useState(false); 

  const toggleCheck = () => {
    setChecked(current => !current);
  }

  const toggleCheckKeyboard = (e) => {
    // Return Key (13) 
    if(e.keyCode === 13){
      setChecked(current => !current);
    }
  }

  // Handle text with carriage returns. Split into multiple paragraphs. 
  // ? makes the variable an optional
  // key needed - index of the resultantarray item used
  let splitText = text?.split('\n').map((i, index) => 
    <p key={index}>
      {i}
    </p>
  );

  return (
    <div className={styles.exerciseContainer}>
      <div className={styles.exerciseLine}></div>
      {/* TabIndex = 0 allows element to be focused */}
      <div className={cx(styles.checkbox, styles.exerciseCheckbox, checked ? styles.checked : styles.notChecked)} onClick={toggleCheck} onKeyDown={toggleCheckKeyboard} tabIndex="0">
        <FontAwesomeIcon icon={checked ? faCheck : faMinus}/>
      </div>
      <h3 className={styles.exerciseTitle}>EXERCISE</h3>
      <div className={styles.exerciseText}>{splitText}</div>
    </div>
  );
}

export default Exercise;