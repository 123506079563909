import React from 'react';
import cx from 'classnames';
import '../Styling/App.css';
import styles from '../Styling/Outro.module.css';
import Dots from './Dots.js';
import downArrow from '../images/down-arrow-1px.png';
import { InView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function Outro() {

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 1
      }
    }
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  return (
    <div className={styles.outroContainer}>

      <div className={styles.outroShadowHide}></div>
      
      <div className={styles.outroTop}>
        <InView threshold={0.25}>
          {({ ref, inView }) => (
            <motion.div className="mainContainer" ref={ref}
              variants={container}
              initial="hidden"
              animate={inView ? "show" : "hidden"}
              transition={{delay: 0.2}}
            >
              <motion.p variants={item}>We met three young <span className="boldParagraph">people . . .</span></motion.p>
              <motion.p variants={item}>. . . and heard three different <span className="boldParagraph">experiences</span></motion.p> 
            </motion.div>
          )}
        </InView>
      </div>

      <div className={styles.outroBottom}>
        <div className={cx("mainContainer", styles.outroBottomContent)}>
          <div className={styles.outroTalking}>
              <h4>This is when</h4>
              <p className='boldParagraph'>. . .You keep talking</p>
          </div>
          
            <p className={styles.outroConversations}>You might want to carry on some of the conversations you've had or start new ones</p>
          
        </div>
      </div>

      <div className={cx("mainContainer", styles.outroArrowDot)}>
          <img src={downArrow} className={styles.downArrow} alt="Down Arrow"/>
        <div className={styles.outroDots}>
          <Dots/>
        </div> 
      </div>

    </div>
  );
}

export default Outro;