import React  from 'react';
import '../../Styling/App.css';
import styles from '../../Styling/Dialogue.module.css';
import Exercise from './Exercise';
import Paragraph from './Paragraph'
import Question from './Question';
import Message from './Message';

function DialogueLeah() {

  const narrative = {
    para1: "Let's think in facts first.\nTogether, recap her story.",
    q1: "Where's the violence?",
    para2: "Let's explore your reaction to it.",
    q2: "What did it make you feel or think?",
    para3: "Something happened in the scene.\nSam (Leah's friend) asked a question...",
    message1: "What's happened?",
    q3: "How did Sam's question change the rest of the scene?",
    para4: "At first Leah was reluctant to open up, but Sam persisted, noticing things weren't okay. Later, Leah says...",
    message2: "It's not even late out",
    q4: "Why do you think Leah mentions that it wasn't late?",
    para5: "Thinking about Leah's experience and perhaps some of your own...",
    q5: "Who do you think is responsible for women and girl's safety?",
    exercise: "Leah decides to report what happened to her.\nResearch how you might go about reporting an experience like Leah's and who you might report it to.\nIf you're in a group, share what you find out with one another."
  }

  return (
    <div className={styles.dialogueSection}>
      <div className={styles.dialogueTimeline}></div>
      <div className={styles.dialogueContent}>
        <Paragraph text={narrative.para1}/>
        <Question text={narrative.q1} tooltip={true}/>
        <Paragraph text={narrative.para2}/>
        <Question text={narrative.q2}/>
        <Paragraph text={narrative.para3}/>
        <Message text={narrative.message1}/>
        <Question text={narrative.q3}/>
        <Paragraph text={narrative.para4}/>
        <Message text={narrative.message2}/>
        <Question text={narrative.q4}/>
        <Paragraph text={narrative.para5}/>
        <Question text={narrative.q5}/>
        <Exercise text={narrative.exercise}/>
      </div>
    </div>
  );
}

export default DialogueLeah;