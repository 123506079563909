import React from 'react';
import '../Styling/App.css';
import styles from '../Styling/Lightbulb.module.css';
import lightbulbBlack from '../images/lightbulb-black-on.png';
import lightbulbWhite from '../images/lightbulb-white-off.png';
import lightswitch from '../images/lightswitch-white.png';
import { motion } from 'framer-motion';

function Lightbulb({isDark, setisDark, moveLightbulb}) {

const clickLightSwitch = (() => {
    setisDark(false);
})

const clickLightSwitchKeyboard = (e) => {
    // Return Key (13)
    if(e.keyCode === 13){
        setisDark(false);
    }
}

    return(
        <> 
        {isDark &&
            <motion.div className={styles.lightbulbContainer}
                initial={{opacity: 1}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                {/* This is just to preload the black lightbulb to avoid lay in image switching */}
                <img className={styles.preloadLightbulb} src={lightbulbBlack} alt=""/>
            </motion.div>
        }
        {moveLightbulb &&
            <div className="mainContainer">
                <motion.img className={styles.lightbulb} src={isDark ? lightbulbWhite : lightbulbBlack} alt="Lightbulb"
                initial={{top: "-35rem"}}
                animate={{top: "-8rem"}}
                transition={{delay: 0.5, duration: 0.4, type: "spring", stiffness: 70}}
                />
                {isDark &&
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5, delay: 1}}
                >
                    <motion.img className={styles.lightswitch} src={lightswitch} onClick={clickLightSwitch} onKeyDown={clickLightSwitchKeyboard} alt="Lightswitch" tabIndex="0"
                        initial={{scale: 1}}
                        animate={{scale: [1, 0.9, 1]}}
                        transition={{repeat: Infinity, repeatDelay: 0, delay: 1, duration: 1.4}}
                    />
                </motion.div>
                }
            </div>
        }
        </>
    )
}

export default Lightbulb;





