import React, { useState, useEffect }  from 'react';
import cx from 'classnames';
import '../../Styling/App.css';
import styles from '../../Styling/Dialogue.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faCheck } from '@fortawesome/free-solid-svg-icons';
import hand from '../../images/hand.png';
import { AnimatePresence, motion } from 'framer-motion';

function Question({text, tooltip}) {

  const [checked, setChecked] = useState(false); 
  const [pointerShown, setPointerShown] = useState(tooltip);

  const toggleCheck = () => {
    setChecked(current => !current);
  }

  const toggleCheckKeyboard = (e) => {
    // Return Key (13)
    if(e.keyCode === 13){
      setChecked(current => !current);
    }
  }

  useEffect(() => {
    if(checked){
      setPointerShown(false);
    }
  }, [checked])

  return (
    <div className={styles.questionContainer}>
      <div className={styles.questionLine}></div>
      <div className={cx(styles.checkbox, checked ? styles.checked : styles.notChecked)} onClick={toggleCheck} onKeyDown={toggleCheckKeyboard} tabIndex="0">
        <FontAwesomeIcon icon={checked ? faCheck : faMinus}/>
      </div>
      <AnimatePresence>
        {pointerShown &&
          <motion.div
            initial={{opacity: 1}}
            exit={{opacity: 0, transition:{duration: 0.2}}}
          >
            <motion.img className={styles.checkboxPointer} src={hand} alt="pointer"
              initial={{scale: 1, rotate: 30}}
              animate={{scale: [1, 0.9, 1], rotate: 30}}
              transition={{repeat: Infinity, repeatDelay: 0, delay: 1, duration: 1.4}}
            />
          </motion.div>
        }
        </AnimatePresence>
      <p className={cx(styles.questionText, "boldParagraph")}>{text}</p>
    </div>
  );
}

export default Question;