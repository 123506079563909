import React, { useState, useEffect } from 'react';
import '../Styling/App.css';
import styles from '../Styling/Entry.module.css';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import tiwLogo from '../images/tiw-logo.png';

function Entry({moveLightbulb, setMoveLightbulb}) {

const [hideContent, setHideContent] = useState(false);
const [hideBackground, setHideBackground] = useState(false);

useEffect(() => {
  window.scrollTo(0, 0)
}, [hideBackground])

const backgroundVarients = {
  animateOut: {
      borderBottom: "0vh",
      borderRight: "0vw"
  }, 
  initial: {
    borderBottom: "200vh",
    borderRight: "200vw"
  }
}

  return (
      <>
        {!moveLightbulb &&
        <div className={styles.entrySection}>
          <AnimatePresence>
            {!hideBackground &&
              <motion.div className={styles.entryBackground}
                variants={backgroundVarients}
                exit={"animateOut"}
                transition={{duration: 0.5, ease: 'easeInOut'}}
                onAnimationComplete={() => setMoveLightbulb(true)}
              >
              </motion.div>
            }
          </AnimatePresence>

          <AnimatePresence>
            {!hideContent &&
              <motion.div className={styles.backgroundPattern}
                initial={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.2}}
              ></motion.div>
            }
            </AnimatePresence>
          
          <AnimatePresence>
            {!hideContent &&
              <motion.div className={cx('mainContainer', styles.entryContent)}
                initial={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.5}}
                onAnimationComplete={() => setHideBackground(true)}
              > 
                {/* <h4>This is When...</h4> */}
                <img className={styles.logo} src={tiwLogo} alt="This Is When logo"/>
                <p>. . . is a workshop experience for young people. You'll watch <span className="highlightParagraph">3 mini films</span> where each film tells a different experience of violence against women and girls. After each film you'll be offered <span className="highlightParagraph">questions and prompts</span> for individuals to consider or groups to discuss.</p>
                <br/>
                <p>This experience works best in a group setting offering young people the opportunity to have conversations about an issue that affects us all. It lasts 30-60 minutes depending on conversation lengths.</p>
                <h3>Trigger Warning</h3>
                <p>The experience is suitable for 14+ and contains references to controlling relationships, online abuse and street harassment. Please be aware there is some moderate language used.</p>
                <motion.button onClick={() => setHideContent(true)}
                  initial={{scale: 1}}
                  exit={{scale: 0}}
                  transition={{duration: 0.5}} 
                >
                  <p className='biggerParagraph'>Start</p>
                </motion.button>
              </motion.div>
            }
          </AnimatePresence>
        </div>
        }
      </>
  );
}

export default Entry;